@tailwind base;
@tailwind components;
@tailwind utilities;


.cards {
  @apply grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-5;
}

.blog .cards {
  @apply grid grid-cols-1 md:grid-cols-2 xl:grid-cols-2 gap-5;
}

.card {
  @apply bg-white shadow-xl rounded-xl flex gap-5 flex-col overflow-hidden
}

.card img {
  @apply w-full;
}

.card .content {
  @apply px-6 h-full flex flex-col min-h-52 gap-5;
}

.card .title {
  @apply text-2xl font-bold text-primary mt-4 min-h-16;
}

.card .btn {
  @apply bg-foreground cursor-pointer text-white h-10 font-bold text-lg flex justify-center items-center hover:text-white hover:bg-accent ease-in-out duration-300;
}
